<template>
	<div>
		<div class="columns">
			<transition name="fade">
				<div
					class="column is-10-tablet is-offset-1-tablet is-10-fullhd"
				>
					<Title>
						<div class="title-exp">
							<span @click="modalOpen = true">
								<Icon
									v-if="permiteAcessarExperiencias"
									class="icon-title-exp is-clickable"
									file="information"
									size="1rem"
								/>
							</span>
							Experiências
						</div>
					</Title>

					<div
						ref="start-page"
						tabindex="-1"
					/>

					<div v-if="permiteAcessarExperiencias">
						<div class="header-buttons">
							<div class="is-4  mr-4">
								<b-button
									type="is-primary"
									outlined
									:class="'tab-button' + ((activeTab == 0) ? ' active' : '')"
									@click="activeTab = 0"
								>
									<Icon
										class="primary-color tab-icon"
										file="experiencias/novas-experiencias"
										size="28px"
									/>

									<span>Novas experiências</span>
								</b-button>
							</div>
							<div class="is-4 mr-4">
								<b-button
									type="is-primary"
									outlined
									:class="'tab-button' + ((activeTab == 1) ? ' active' : '')"
									@click="activeTab = 1"
								>
									<Icon
										class="primary-color tab-icon"
										file="experiencias/minhas-experiencias"
										size="28px"
									/>

									<span>Minhas experiências</span>
								</b-button>
							</div>
							<div class=" is-4">
								<b-button
									type="is-primary"
									outlined
									:class="'tab-button encontro-especialista' + ((activeTab == 2) ? ' active' : '')"
									@click="activeTab = 2"
								>
									<Icon
										class="primary-color tab-icon"
										file="experiencias/encontro-especialista"
										size="28px"
									/>

									<span>Encontros com especialista</span>
								</b-button>
							</div>
						</div>

						<div
							ref="panel"
							class="panel"
						>
							<transition name="fade">
								<div
									v-if="activeTab == 0"
									class="panel-content"
								>
									<ListagemExperiencias
										:data="getNovasExperiencias"
										area="novasExperiencias"
									/>
								</div>
							</transition>

							<transition name="fade">
								<div
									v-if="activeTab == 1"
									class="panel-content"
								>
									<ListagemExperiencias
										:data="getMinhasExperiencias"
										area="minhasExperiencias"
									/>
								</div>
							</transition>

							<transition name="fade">
								<div
									v-if="activeTab == 2"
									class="panel-content"
								>
									<ListagemExperiencias
										:data="getEncontrosComEspecialista"
										area="encontroEspecialista"
									/>
								</div>
							</transition>
						</div>
					</div>

					<div
						v-else-if="isVinculoSelecionado"
						class="py-5 is-flex is-justify-content-center"
					>
						<div class="is-flex is-flex-direction-column is-align-items-center empty-list">
							<div class="column is-5">
								<Icon
									class="primary-color"
									file="experiencias/experiencia_vinculo_incorreto"
									size="100%"
								/>
							</div>

							<h4 class="is-size-5 has-text-centered justify-with-image mb-4">
								Opa! Você não possui esse componente na sua grade curricular.
							</h4>

							<h4 class="is-size-5 is-12 has-text-centered">
								Para acompanhar ou demonstrar interesse nas experiências, é obrigatório que você
								<strong>selecione um vínculo de curso</strong> com este componente no filtro acima!
							</h4>
						</div>
					</div>

					<div
						v-else
						class="py-5 is-flex is-justify-content-center"
					>
						<div class="is-flex is-flex-direction-column is-align-items-center empty-list">
							<div class="column is-5">
								<Icon
									class="primary-color"
									file="experiencias/experiencia_selecione_vinculo"
									size="100%"
								/>
							</div>

							<h4 class="is-size-5 has-text-centered justify-with-image">
								Para acompanhar ou demonstrar interesse nas experiências, é obrigatório que você
								<strong>selecione um vínculo de curso</strong> no filtro acima!
							</h4>
						</div>
					</div>
				</div>
			</transition>

			<ModalExperiencia
				v-if="(modalOpen || !isModalVisualizado) && permiteAcessarExperiencias"
				@close="modalOpen = false"
			/>
		</div>
	</div>
</template>

<script>
import Title from '@components/Title';
import { mapActions, mapGetters } from 'vuex';
import ListagemExperiencias from '@components/experiencias/ListagemExperiencias.vue';
import Icon from '@components/Icon';
import ModalExperiencia from '@components/experiencias/ModalExperiencia.vue';

export default {
	name: 'Experiencias',

	components: {
		ListagemExperiencias,
		Title,
		Icon,
		ModalExperiencia,
	},

	data() {
		return {
			activeTab: 0,
			modalOpen: false,
		};
	},

	computed: {
		...mapGetters('experiencias', [
			'getNovasExperiencias',
			'getMinhasExperiencias',
			'getEncontrosComEspecialista',
			'isLoading',
			'contemDados',
			'isModalVisualizado',
		]),
		...mapGetters('servicos', [
			'permiteAcessarExperiencias',
		]),
		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado',
		]),
		isVinculoSelecionado() {
			return !this.$store.getters['vinculosAcademicos/isVinculoTodos'];
		},
	},

	watch: {
		getVinculoSelecionado() {
			if (!this.isVinculoSelecionado) {
				return;
			}

			if (!this.permiteAcessarExperiencias) {
				return;
			}

			this.todasExperiencias();
		}
	},

	async created() {
		if (this.contemDados) {
			return;
		}

		await this.todasExperiencias();
	},

	methods: {
		...mapActions('experiencias', [
			'todasExperiencias',
		]),
	},

};
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

@media(max-width: 768px) {
	.header-buttons {
		display: flex;
		overflow-y: hidden;
		overflow-x: auto;
	}
}

::v-deep .icon-title-exp {
	margin-right: 1rem;
}

::v-deep .title-exp {
	display: flex !important;
}

::v-deep .tab-icon {
	max-height: 28px;
}

::v-deep .tab-icon path {
	fill: var(--primary) !important;
}

::v-deep .active .tab-icon path  {
	fill: #fff !important;
}


::v-deep .encontro-especialista .tab-icon path {
	fill: var(--dark-blue) !important;
}

::v-deep .encontro-especialista.active .tab-icon path {
	fill: #fff !important;
}

.header-buttons {
	text-align: center;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding-left: 0;
	padding-right: 0;
	margin-left: 0;
	margin-right: 0;

	padding-bottom: 2.5rem;
}
::v-deep .tab-button {
	border-radius: 1rem;
	background-color: #fff !important;
	border-color: transparent !important;
	min-height: 5rem;
	width: 17rem;
	font-size: 1.25rem;
	font-weight: 400;


	&:hover {
		color: var(--white) !important;
		background-color: var(--primary-hover) !important;

		.tab-icon path {
			fill: #fff !important;
		}
	}

	&.button.is-primary.is-focused,
	&.button.is-primary:focus {
		color: var(--primary);
		box-shadow: unset !important;
	}
}

.active {
	color: #fff !important;
	background-color: var(--primary) !important;
}

::v-deep .encontro-especialista {
	min-width: 210px;
	color: var(--dark-blue) !important;

	&:hover {
		.tab-icon path {
			fill: #fff !important;
		}
		color: #fff !important;
		background-color: var(--dark-blue) !important;
	}

	&.active {
		color: #fff !important;
		background-color: var(--dark-blue) !important;
		.tab-icon path {
			fill: #fff !important;
		}
	}
}

::v-deep {
	.panel {
		border-radius: 1rem;
	}
}
</style>
